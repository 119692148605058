body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dashboard-stats-card {
  flex: 1;
  color: white !important;
  cursor: pointer;
  opacity: .6;
}

.dashboard-stats-card:hover {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}
.amcharts-amexport-item amcharts-amexport-item-level-0 amcharts-amexport-item-blank active {
  color:white!important;

}
.amcharts-amexport-item {
  background-color:black!important;
  color:white!important;
}

.chart {
  width: 100%;
  height: 250px;
  border: 1px solid #eee;
}

#chartdiv4 {
  float: left;
  margin: 0 20px 20px 0;
  width: 30%;
}

.main {
  max-width: 1500px;
  margin: 0 auto;
}

h2 {
  margin-top: 2em;
  clear: both;
}

br {
  clear: both;
}

